$theme-colors: (
    primary: #394D77
) !default;

$navbar-dark-color: #D6A419;
$navbar-dark-hover-color: #e8ba3a;

@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Marcellus+SC&display=swap');

/* Theme colors
#002146, #394D77, #E5F0FF, #D6A419, #FFEECA, #877555
*/

html,body {
    height: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

.wrapper {
    height:100%;
    width: 100%;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
    background-image: -webkit-gradient(linear,left top,left bottom,from(#6e9cd5),to(#f5f5f5));
    background-image: gradient(linear,left top,left bottom,from(#6e9cd5),to(#f5f5f5));
    /* background-image:url('/img/background.png'); */
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
}

.bg-primary {
    background-color: #002146 !important;
}

.navbar {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
}

.navbar-brand {
    font-family: 'Marcellus SC', serif;
    font-size: 1.7rem;
}

.navbar-dark .navbar-nav .nav-link {
    color: #D6A419;
}

.card {
    margin-bottom: 1rem;
}

.roll {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    font: 300 30px/1 'Open Sans Condensed', sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    animation: 30s credits linear infinite;

    &__name {
        margin-bottom: 50px;
        font-size: 20px;
    }
}

@keyframes credits {
    0% { top:  100%; }
  100% { top: -200%; }
}

#reading {
    position: relative;
    width: 60%;
    padding: 10px 20px;
    font-size: 20px;
    position: relative;
    
    color: #2E8DEF;
    background: #333333;
    border-bottom: 3px solid #2E8DEF;

    &:after {
        content: " ";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        box-sizing: content-box;
        
        background: #333333;
        border-bottom: 3px solid #2E8DEF;
        border-right: 20px solid #2E8DEF;
        
        transform-origin: bottom left;
        -ms-transform: skew(-30deg, 0deg);
        -webkit-transform: skew(-30deg, 0deg);
        transform: skew(-30deg, 0deg);
    }
}